import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				 Pure Bliss Cleaning Co
			</title>
			<meta name={"description"} content={"Our team is passionate about providing exceptional cleaning services that will enhance your living space. With Pure Bliss you will enter a world of cleanliness where every detail matters."} />
			<meta property={"og:title"} content={" Pure Bliss Cleaning Co"} />
			<meta property={"og:description"} content={"Our team is passionate about providing exceptional cleaning services that will enhance your living space. With Pure Bliss you will enter a world of cleanliness where every detail matters."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1" />
		</Components.Header>
		<Section padding="80px 0 80px 0" background="#f0ede2" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8">
			<Override
				slot="SectionContent"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				align-items="center"
				display="flex"
				grid-column="1 / span 3"
				lg-grid-column="auto / auto"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Our Services
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					md-text-align="center"
					text-align="center"
					max-width="800px"
					sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					From sparkling floors to serene spaces—discover the full spectrum of cleanliness with Pure Bliss
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Residential Cleaning
				</Text>
				<Text
					margin="0px 0px 0 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Experience the essence of a clean home. Our residential cleaning services cover everything from living rooms to bedrooms, ensuring every corner radiates cleanliness and tranquility. This includes dusting, vacuuming, mopping, and more, all tailored to your specific needs.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Eco-Friendly Cleaning
				</Text>
				<Text
					margin="0px 0px 0 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Take advantage of eco-friendly cleaning. We use environmentally safe and sustainable products, ensuring a clean that protects your health and the planet. Our eco-friendly approach means every surface shines without guilt.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Deep Cleaning
				</Text>
				<Text
					margin="0px 0px 0 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					For those spaces that require extra attention, our deep cleaning service is ideal. From kitchen appliances to bathroom grout, we get into every detail, leaving your home impeccably clean.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Customized Cleaning Plans
				</Text>
				<Text
					margin="0px 0px 0 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Your home is unique, and so are your cleaning needs. We offer customized cleaning plans that adapt to your lifestyle and schedule, providing personalized service to keep your home looking its best.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09:52:47.694Z"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
						srcSet="https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09%3A52%3A47.694Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09%3A52%3A47.694Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09%3A52%3A47.694Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09%3A52%3A47.694Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09%3A52%3A47.694Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09%3A52%3A47.694Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-2.jpg?v=2024-02-14T09%3A52%3A47.694Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09:52:47.669Z"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						srcSet="https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09%3A52%3A47.669Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09%3A52%3A47.669Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09%3A52%3A47.669Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09%3A52%3A47.669Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09%3A52%3A47.669Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09%3A52%3A47.669Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-1.jpg?v=2024-02-14T09%3A52%3A47.669Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					We invite you to experience the unparalleled service and peace of mind that Pure Bliss Cleaning Co. provides. For more information about our services and

 to develop a cleaning plan tailored to your needs, please contact us. Discover how we can transform your home into a sanctuary of cleanliness and serenity.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb71eefdcae00021dd9faf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});